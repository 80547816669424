<script>
import { onMounted, onBeforeMount,ref,  reactive, watch } from 'vue'
import { needLoading } from '@/reactive'
import { http } from '@/http'
import { useRouter, useRoute } from 'vue-router'
import trustSign from './trust-sign'
import channelStepBar from '@/components/channel-step-bar'


export default {
  components: {
    trustSign,
    channelStepBar
  },
  setup() {
    let enumType = ref('')
    let contractInfo = reactive({})
    let isSigned = ref(false)
    let drawVisible = ref(false)
    let router = useRouter()
    let route = useRoute()
    // 调用协议枚举

    function getEnum() {
      http.post('/?cmd=com.xqxc.api.pigear.biz.platform.PfEnumService.getEnum', {
        enumName: 'AgreementTypeEnum'
      }).then(res => {
        if(res.result) {
          if(!route.query.type) {
            enumType.value = res.result[2]
          }
          else {
            if(route.query.type === 'broker') {
              enumType.value = res.result[4]
            }
          }
        
        }
      })
    }
    
    
    function getBrokerBuyProtocol(contractType, signImgUrl = '') {
      needLoading.value = true
      let apiUrl = ''
      if(!route.query.type) {
        apiUrl = '/?cmd=com.xqxc.api.pigear.biz.account.BrokerTaxCommissionRecordService.getAgreement'
      }
      else if(route.query.type === 'broker') {
        apiUrl = '/?cmd=com.xqxc.api.pigear.biz.account.BrokerTaxCommissionRecordService.getBrokerBuyProtocol'
      }
      http.post(apiUrl, {
        contractType,
        signImgUrl
      }).then(res => {
        if(res.result) {
          contractInfo.agreementName = res.result.agreementName
          contractInfo.contractContent = res.result.contractContent
          contractInfo.isSubmit = res.result.isSubmit
        }
      })
    }

    function handleDrawPageVisible() {
      drawVisible.value = true
    }

    
    function handleDrawPageHide(data) {
      drawVisible.value = false
      if(data) {
        getBrokerBuyProtocol(enumType.value.code, data)
      }
      if(route.query.type === 'broker') {
        isSigned.value = true
      }
    }

    function submitTaxCommission() {
      if(!contractInfo.isSubmit) return
      http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerTaxCommissionRecordService.submitTaxCommission')
        .then(res => {
          if(contractInfo.isSubmit)
            router.push('/bind-bankcard')
        })
    }

    function submitBrokerBuyProtocol() {
      http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerTaxCommissionRecordService.submitBrokerBuyProtocol')
        .then(res => {
          if(res.result === 1) {
            router.push('/open-service')
          }
        })
    }
    onBeforeMount(getEnum)

    watch(() => enumType.value, (a, b) => {
      getBrokerBuyProtocol(a.code)
    })

    watch(() => contractInfo.agreementName, (a, b) => {
      if(enumType.value.code === 'BROKER_BUY_PROTOCOL') {
        document.title = a
      }
      else if(enumType.value.code === 'DELEGATE_TAX_CERTIFICATE_CONTRACT') {
        document.title = '报税委托'
      }
    })
    return {
      contractInfo,
      isSigned,
      drawVisible,
      handleDrawPageVisible,
      handleDrawPageHide,
      submitTaxCommission,
      submitBrokerBuyProtocol
    }

  }
}
</script>
<template>
  <div class="page">
    <channel-step-bar v-if="$route.query.type === 'broker'" />
    <template v-if="!drawVisible">
  <div class="content" style="padding:0 .3rem" v-html="contractInfo.contractContent" />
      <div class="btn-area" v-if="!$route.query.type">
        <div>
          <p><van-button style="padding:0 1.2rem" type="primary" @click="handleDrawPageVisible">签名</van-button></p>
          <p><van-button style="padding:0 1rem" @click="submitTaxCommission">下一步</van-button></p>
        </div>
      </div>
      <div class="btn-area" style="bottom:0;" v-if="$route.query.type =='broker'">
        <div v-if="!isSigned">
          <p><van-button block square style="padding:0 1.2rem" type="primary" @click="handleDrawPageVisible" >我已阅读完成，立即签署</van-button></p>
        </div>
        <div v-else>
          <p><van-button block square @click="handleDrawPageVisible">重新签署签名</van-button></p>
          <p><van-button block square type="primary" @click="submitBrokerBuyProtocol">确认签名无误</van-button></p>
        </div>
      </div>
    </template>
    <trust-sign v-else @hidePage="handleDrawPageHide" />
  </div>
</template>
<style scoped>
  .page { padding-bottom:2rem }
  .btn-area { position:fixed; bottom:.5rem; left: 0; width:100% }
  .btn-area div { display:table; width:100% }
  .btn-area p { display:table-cell; width:50%; text-align: center}
  .btn-area p:last:child { }
  /deep/ img { vertical-align: middle;max-width: 2.5rem;max-height: 1rem}
  .content { position:relative; }
  /deep/ .content p { line-height: 1.3}
  /deep/ .content .stamp-img {    position: absolute;
    bottom: .6rem;
    left: 3rem;}
  /deep/ .content .stamp-img img { max-height:100% }

  /deep/ .media-wrap,
  /deep/ .image-wrap {
  max-width:100%;
  margin:.2rem 0
}

/deep/ .media-wrap img,
/deep/ .image-wrap img {
  max-width:100%;
  max-height:2.8rem
}

</style>
