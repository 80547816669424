<script>
import { ref, onMounted } from 'vue'
import { Toast } from 'vant'
import { upload } from 'qiniu-js'
import { uuid, dataURLtoFile,trimCanvas } from '@/utils'
import { needLoading } from '@/reactive'
import qiniuUpload from '@/utils/qiniu-upload'

export default {
  props: {
    visible: {
      default: false,
      type: Boolean
    }
  },
  setup(props, { emit }) {
    let signaturePad = ref(null)   
    let image = ref(null)

    function clearSignature() {
      signaturePad.value.clearSignature()
    }

    function saveSignature() {
      let canvas = document.querySelector('canvas')
      let { data } = signaturePad.value.saveSignature() 
      if(!data) {
        Toast('请签名!')
        return false
      }
      needLoading.value = true
      let drawCanvas = trimCanvas(canvas)
      let files = dataURLtoFile(drawCanvas.toDataURL(), uuid())
      qiniuUpload(files, {
        errorCallback() {
          Toast.fail('签名上传失败！')
        },
        completeCallback(res) {
          emit('hidePage', res.result)
        }
      })

    }
    return {
      signaturePad,
      image,
      clearSignature,
      saveSignature
    } 
  }
}
</script>
<template>
  <div class="page">
    <VueSignaturePad width="100vh" height="100vh" ref="signaturePad" />
    <div class="btn-area">
      <div>
        <p><van-button  style="padding:0 1rem" @click="clearSignature">清除签名</van-button></p>

        <p><van-button style="padding:0 1rem" type="primary" @click="saveSignature" >确认签名</van-button></p>
      </div>
    </div>
  </div>
</template>
<style scoped>
  .page {overflow: hidden;position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 1000;background:#fff }
  .btn-area { position:absolute; bottom:.5rem; left: 0; width:100% }
  .btn-area div { display:table; width:100% }
  .btn-area p { display:table-cell; width:50%; text-align: center}
  .btn-area p:last:child { }
</style>